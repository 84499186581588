<template>
  <div v-if="song" class="waveform">
    <a
      @click="playMiddle"
      class="sr-only"
      v-text="'Play Middle'"
    />

    <div
      v-if="showControls"
      class="controls"
    >
      <a
        href="#"
        class="control__toggle"
        @click.prevent="togglePlay"
        :title="wavesurferState.isPlaying ? 'Pause' : 'Play'"
      >
        <i
          :class="[
                        'fa',
                        `fa-${wavesurferState.isPlaying ? 'pause' : 'play'}`
                    ]"
        />
      </a>
    </div>

    <div class="wave-wrapper">
      <div
        class="wave"
        @click.stop="onWaveContainerClick"
        ref="waveContainer"
      />

      <span
        v-if="wavesurferState.time"
        class="timestamp"
      >
                <small v-text="wavesurferState.time"/>
            </span>
    </div>
    <transition name="fade-fast">
      <div
        v-show="!wavesurferState.isLoaded"
        class="loading"
      >
        Loading...
      </div>
    </transition>
  </div>
</template>

<script>
import Wavesurfer from 'wavesurfer.js';

export default {
  name: 'WavForm',
  props: {
    song: {
      type: Object,
      require: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    noplaymiddle: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      songs_base: '/songs/play/', // 'https://wavpooltracks.s3-us-west-1.amazonaws.com/',
      wavesurfer: null,
      wavesurferState: {
        isLoaded: false,
        isPlaying: false,
        time: ''
      },
      wavesurferConfig: function (container) {
        return {
          container: container,
          backend: 'MediaElement',
          waveColor: '#999999',
          progressColor: '#000000',
          cursorWidth: 0,
          barWidth: 2,
          barRadius: 2,
          height: 90,
          barGap: 5,
          hideScrollbar: true,
          responsive: true,
          closeAudioContext: true
        };
      }
    };
  },
  mounted() {
    this.wavesurfer = this.createWavesurferInstance(this.$refs.waveContainer);
    document.body.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.body.removeEventListener('keydown', this.onKeyDown);
    this.wavesurfer.destroy();
  },
  computed: {
    showControls() {
      return this.wavesurferState.isLoaded;
    }
  },
  methods: {
    createWavesurferInstance(container) {
      const instance = Wavesurfer.create(this.wavesurferConfig(container));

      instance.on('ready', this.onReady.bind(this))
      instance.on('play', this.onPlay.bind(this))
      instance.on('pause', this.onPause.bind(this))
      instance.on('finish', this.onFinish.bind(this))
      instance.on('audioprocess', this.onAudioProcess.bind(this))

      this.$http
        .get(`${this.songs_base}${this.song.id}`)
        .then(response => response.data.song_url)
        .catch(console.log)
        .then(url => instance.backend && instance.load(url));

      return instance;
    },
    onKeyDown (event) {
      const KEYBOARD_SPACE = 32;

      if (
        event.target === document.body
        && event.which === KEYBOARD_SPACE
      ) {
        this.togglePlay();
        event.preventDefault() // Spacebar can trigger unwanted download click events
      }
    },
    padNum(num) {
      return num < 10 ? `0${num}` : `${num}`
    },
    playMiddle() {
      if (!this.noplaymiddle) {
        this.wavesurfer.seekAndCenter(0.5);
      } else {
        this.wavesurfer.play()
      }
    },
    play() {
      this.wavesurfer.play()
    },
    pause() {
      this.wavesurfer.pause()
    },
    togglePlay() {
      this.wavesurfer.playPause();
    },
    onReady() {
      this.wavesurferState.isLoaded = true

      if (this.autoplay) {
        this.playMiddle()
      }
    },
    onPlay() {
      this.wavesurferState.isPlaying = true
    },
    onPause() {
      this.wavesurferState.isPlaying = false
    },
    onFinish() {
      this.wavesurferState.isPlaying = false
    },
    onAudioProcess() {
      let elapsedTime = this.formatTime(this.wavesurfer.getCurrentTime());
      let totalTime = this.formatTime(this.wavesurfer.getDuration());

      this.wavesurferState.time = `${elapsedTime} / ${totalTime}`
    },
    formatTime(seconds) {
      const timeInSeconds = Math.round(seconds);

      const displayTime = {
        minutes: Math.floor(timeInSeconds / 60),
        seconds: timeInSeconds % 60
      };

      return `${this.padNum(displayTime.minutes)}:${this.padNum(displayTime.seconds)}`;
    },
    onWaveContainerClick() {
      // There's a small issue with wavesurfer that causes the song to pause when clicked
      // Adding a small timeout seems to fix it
      if (this.wavesurferState.isPlaying) {
        setTimeout(() => this.play(), 25)
      }
    }
  },
  watch: {
    song(newSong, oldSong) {
      if (oldSong && newSong) {
        if (newSong.id !== oldSong.id) {
          this.wavesurfer.destroy();

          this.wavesurfer = this.createWavesurferInstance(this.$refs.waveContainer);
          this.wavesurferState.isLoaded = false;
          this.wavesurferState.isPlaying = false;
          this.wavesurferState.time = '';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.waveform {
  position: relative;
  display: flex;
  align-items: center;
}

.wave-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
}

.wave {
  width: 100%;
  cursor: pointer;
}

.loading {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
}

.controls {
  margin-right: 15px;
}

.control__toggle {
  display: inline-block;
  position: relative;
  border: 2px solid #bbb;
  color: #bbb;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  transition: border-color 150ms ease-out;

  &:hover {
    border-color: black;
    color: black;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 19px;
    transform: translate(-50%, -50%);
    transition: color 150ms ease-out;

    &.fa-play {
      left: 54%;
    }
  }
}

.timestamp {
  position: absolute;
  z-index: 5; /* Situated above <wave /> */
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 1px 13px 2px;
  display: inline-block;
  border-radius: 20px;
}
</style>
